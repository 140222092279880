
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
@Component({
  props: {},
  components: { Breadcrumbs },
})
export default class IdNumberMerge extends Vue {
  zzlApi: any;
  zzlFormat: any;
  form: any = {
    oldAuthType: "",
    newAuthType: "",
    oldIdNumber: "",
    newIdNumber: "",
  };
  authTypeList: any = [];
  formRules: any = {
    oldAuthType: [{ required: true, message: "请选择", trigger: "change" }],
    newAuthType: [{ required: true, message: "请选择", trigger: "change" }],
    oldIdNumber: [{ required: true, message: "请输入", trigger: "blur" }],
    newIdNumber: [{ required: true, message: "请输入", trigger: "blur" }],
  };

  // async onSubmit() {
  //   (this.$refs["form"] as HTMLFormElement).validate(async (valid: any) => {
  //     if (valid) {
  //       let res = await this.zzlApi.insidemanage.UpdateInside(this.form);
  //       if (res.code == 0) {
  //         this.$message.success("修改成功");
  //       } else {
  //         this.$message.error("修改失败");
  //       }
  //     } else {
  //       console.log("error submit!!");
  //       return false;
  //     }
  //   });
  // }
  //合并预览
  mergePreview() {
    
    (this.$refs["form"] as HTMLFormElement).validate(async (valid: any) => {
      let _this = this
      if (valid) {
        const last_router: any = this.$route.name;
        window.sessionStorage.setItem("last_router", last_router);
        sessionStorage.setItem('idMerge',JSON.stringify(this.form))
        this.$router.push({
          name:'MergePreview'
        })
      } else {
        return false;
      }
    });
  }

  initData() {
    let dictionary: any = JSON.parse(
      window.sessionStorage.getItem("dictionary")!
    ).filter((itm: any) => {
      return itm.key == "AuthType";
    })[0].values;
    this.authTypeList = this.$store.state.authType || dictionary || [];
    this.authTypeList &&
      (this.authTypeList = this.authTypeList.filter((item: any) => {
        return item.value;
      }));
  }
  async mounted() {
    await this.initData();
  }
}
